import $ from 'jquery';


function Scroll() {
    let scroll = this;

    this.actions = [];
    this.lastTop = $(window).scrollTop();
    this.direction = 'down';


    $(window).on('scroll', () => {
        let newTop = $(window).scrollTop();
        if(Math.abs(this.lastTop-newTop) < 10) return;

        this.direction = (newTop > this.lastTop) ? 'down' : 'up';

        testActions(newTop);

        this.lastTop = newTop;
    });


    function testActions(scrollTop) {

        $.each(scroll.actions, (index, action) => {
            let top,
                bottom,
                $el;
            if(action.hasEl) {
                $el = $(action.action.el);
                top = $el.offset().top;
                bottom = $el.offset().top + action.action.el.getBoundingClientRect().height;
            } else if(action.hasTopBottom) {
                top = action.action.top;
                bottom = action.action.bottom;
            }

            if(typeof action.action.offset !== "undefined") {
                if(typeof action.action.offset == "object") {
                    if(action.action.offset.top){
                        top = addOffset(action.action.offset.top, top);
                    }
                    if(action.action.offset.bottom) {
                        bottom = addOffset(action.action.offset.bottom, bottom, true);
                    }
                } else {
                    top = addOffset(action.action.offset, top);
                    bottom = addOffset(action.action.offset, bottom, true);
                }
            }

            if(typeof top =="undefined" || typeof bottom =="undefined"){
                return;
            }

            if(top <= scrollTop && top > scroll.lastTop      ||      bottom > scrollTop && bottom <= scroll.lastTop) {
                if(action.action.in) {
                    action.action.in.apply(null, [scroll.direction, scrollTop]);
                }
            } else if(bottom <= scrollTop && bottom > scroll.lastTop       ||       top > scrollTop && top <= scroll.lastTop) {
                if(action.action.out) {
                    action.action.out.apply(null, [scroll.direction, scrollTop]);
                }
            }
        });
    }


    function addOffset(offset, value, isBottom=false) {
        let offsetVal,
            isPercent = false,

            offsetMatch = offset.match(/(-?\d+)(%|px)?/);

        if(!offsetMatch || offsetMatch.length !== 3) return value;

        offsetVal = parseFloat(offsetMatch[1]);
        if(offsetMatch[2] == '%') isPercent = true;


        if(isPercent) {
            offsetVal = (offsetVal/100) * viewport().height;
        }

        return isBottom ? (value+offsetVal) : (value-offsetVal);
    }


    this.isElement = function(o){
        return (
            typeof HTMLElement === "object" ? o instanceof HTMLElement : //DOM2
                o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName==="string"
        );
    }
}

Scroll.prototype.addAction = function(action) {
    let hasEl =(typeof action.el !== "undefined" &&
        this.isElement(action.el));
    let hasTopBottom = (typeof action.top !== "undefined" && typeof action.bottom !== "undefined");
    if(hasEl || hasTopBottom) {
        this.actions.push({action, hasEl, hasTopBottom});
    }
};

module.exports = Scroll;
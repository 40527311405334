import $ from 'jquery';
import Glide, { Controls, Breakpoints, Autoplay } from '@glidejs/glide/dist/glide.modular.esm';
import anime from 'animejs';

import Plyr from 'plyr';

require('../vendor/imagesloaded.pkgd.min');
require('../vendor/jquery-imagefill');

const Scroll = require('./scroll');

$('body').addClass('has-js');

if(is_touch_device()) $('body').addClass('is-touch');


$(document.body).on('kd_tas:video_player:modal:ready', function(e, $modal, $player) {
    let $playerEl = $('<div data-plyr-provider="vimeo" data-plyr-embed-id="'+$player.data('video-id')+'"></div>');
    let $videoContainer = $modal.find('.video-player--video_container');
    $videoContainer.html($playerEl);

    $modal.find('.video-player--video_controls').remove();

    const player = new Plyr($playerEl.get(0), {autoplay:true});
});

var videoLoaded = false;
var video;

var $homepageIntro = $('.homepage-intro');

var $homepageBackground = $('.homepage-background');
$homepageBackground.css({backgroundColor: 'transparent', backgroundImage: 'url('+$homepageBackground.data('src')+')'});
$homepageBackground.animate({opacity: 1});


var $homepagePlay = $('.homepage-intro .intro-play');

var $homepageContent = $('.homepage-intro .content');

var $homepageVideoControls = $('.homepage-intro .video-controls');

var $homepageLocations = $('.homepage-intro .homepage-locations');
var $homepageEndContent = $('.homepage-intro .end-content');


function onVideoPlay() {
    $homepageIntro.css({height: $homepageIntro.outerHeight()});

    $homepageContent.animate({opacity: 0}, () => {$homepageContent.css({display: 'none'})});
    $homepageVideoControls.css({display: 'block'}).animate({opacity: 1});

    $homepageEndContent.animate({opacity: 0}, () => {$homepageEndContent.css({display: 'none'})});
    $homepageLocations.animate({opacity: 0}, () => {$homepageLocations.css({display: 'none'})});
}
function onVideoPause() {
    $homepageContent.css({display: 'block'}).animate({opacity: 1});
    $homepageVideoControls.animate({opacity: 0}, () => {$homepageVideoControls.css({display: 'none'})});
}

function onVideoEnded() {
    $homepageLocations.css({display: 'block'}).animate({opacity: 1});
    $homepageEndContent.css({display: 'block'}).animate({opacity: 1});
}

$(document.body).on('click', '.homepage-intro .intro-play', function() {
    if(is_mobile_device()) {
        let videoMatch = $homepageBackground.data('video').match(/https?:\/\/player\.vimeo\.com\/external\/(.*?)\./);
        if(videoMatch && videoMatch[1]) {
            let $dummy = $('<div data-use-plyr="true" data-video-id="'+videoMatch[1]+'"></div>');
            $(document.body).trigger('kd_tas:video_player:modal:show', $dummy);
        }
    } else {
        onVideoPlay();
        if(videoLoaded) {
            video.get(0).play();
        } else {
            video = buildVideo(true);
        }
    }
});

$(document.body).on('click', '.homepage-intro .video-controls .homepage-pause', function() {
    video.get(0).pause();
    onVideoPause();
});

$(document.body).on('click', '.homepage-intro .video-controls .homepage-restart', function() {
    video.get(0).currentTime = 0;
    video.get(0).play();
    onVideoPlay();
});

$(document.body).on('click', '.homepage-intro .video-controls .homepage-end', function() {
    video.get(0).currentTime = video.get(0).duration;
});

function buildVideo(autoPlay) {

    var newVideo = $('<video playsinline muted poster="'+$homepageBackground.data('src')+'"><source src="'+$homepageBackground.data('video')+'" type="video/mp4"></video>');


    var video = newVideo.get(0);

    newVideo.css({
        //position:'absolute',
        //left: 0,
        //top: 0,
        //width: '100%',
        //height: '100%'
    });

    var $videoHolder = $homepageBackground.clone().insertBefore($homepageBackground);
    newVideo.appendTo($videoHolder);



    video.onloadedmetadata = function() {
        videoLoaded = true;

        $videoHolder.imagefill({
            target: 'video',
            runOnce:true
        });
        /*newVideo.css({
            transform:'translateY(-50%)',
            top: '50%',
        });*/

        if(autoPlay) {
            video.play();
            $videoHolder.css({opacity: 1});
            $homepageBackground.animate({opacity: 0});

            onVideoPlay();
        }
    };
    video.onended = function() {
        //onVideoPause();
        onVideoEnded();
    };

    return newVideo;
}












function concertinaEffect() {
    $('.view').each( function() {

        var $item 	= $( this ),
            itemWidth = $item.width(),
            sliceWidth = Math.round(itemWidth/5);

        if(!$item.hasClass('concertina-effect-initialized')) {
            var img		= $item.children( 'img' ).attr( 'src' ),
                struct	= '<div class="slice s1">';
            struct	+='<div class="slice s2">';
            struct	+='<div class="slice s3">';
            struct	+='<div class="slice s4">';
            struct	+='<div class="slice s5">';
            struct	+='</div>';
            struct	+='</div>';
            struct	+='</div>';
            struct	+='</div>';
            struct	+='</div>';

            var $struct = $( struct );

            $item.find( 'img' ).remove().end().append( $struct ).find( 'div.slice' ).css( 'background-image', 'url(' + img + ')' ).prepend( $( '<span class="overlay" ></span>' ) );


            $item.addClass('concertina-effect-initialized');
        } else {
            itemWidth = $item.width();
            sliceWidth = Math.round(itemWidth/5);

            $item.off('mouseenter mouseleave');
        }

        $item.find('.slice').css({width: sliceWidth, backgroundSize:itemWidth+'px auto'});
        $item.find('.overlay').css({width: sliceWidth});
        $item.find('.s2, .s3, .s4, .s5').css({transform: 'translate3d('+sliceWidth+'px,0,0)'});
        for(var i=0; i<5; i++) {
            $item.find('.s'+(i+1)).css({backgroundPosition: (-1*i*sliceWidth)+'px center'});
        }

        $item.on('mouseenter', hoverOverItem);
        $item.on('mouseleave', hoverOffItem);

        function hoverOverItem() {
            var transformWidth = sliceWidth-1;
            $item.find('.s2').css({transform: 'translate3d('+transformWidth+'px,0,0) rotate3d(0,1,0,-45deg)'});
            $item.find('.s3, .s5').css({transform: 'translate3d('+transformWidth+'px,0,0) rotate3d(0,1,0,90deg)'});
            $item.find('.s4').css({transform: 'translate3d('+transformWidth+'px,0,0) rotate3d(0,1,0,-90deg)'});
        }
        function hoverOffItem() {
            $item.find('.s2, .s3, .s4, .s5').css({transform: 'translate3d('+sliceWidth+'px,0,0)'});
        }

    } );
}

if(!is_mobile_device() && $(window).width() >= 992) {
    concertinaEffect();
    var lastWidth = $(window).width();
    $(window).on('resize', function() {
        let newWidth = $(window).width();
        if(newWidth !== lastWidth) {
            concertinaEffect();
        }
        lastWidth = newWidth;
    });
} else {
    $(document.body).on('click', '.view:not(.hover)', function() {
        $(this).addClass('hover');
    });
    $(document.body).on('click', '.view .view-back .view-close', function() {
        $(this).closest('.view').removeClass('hover');
    });
}



if(is_touch_device()) {
    $('[data-text-touch]').each(function() {
        var $el = $(this);
        $el._originalText = $el.html();
        $el.html($el.data('text-touch'));
    });
}




Glide.prototype.LazyLoad = function() {
   this.on(['mount.after', 'run.after'], () => {
       if(kdTasmaniaGeneral) {
           kdTasmaniaGeneral.lazyLoad.update();
       }

       let $glide = $(this.selector);
       for(let i=this.index; i<this.index+this.settings.perView; i ++) {
           let slide = $glide.find('.glide__slides .glide__slide:not(.glide__slide--clone)').get(i);
           let $slide = $(slide);

           /*$slide.find('.glide-image-cover').each(function() {
               $(this).find('img').css({objectFit: 'unset', width: 'auto', height: 'auto'});
               setTimeout(() => {
                   $(this).imagefill({runOnce: true});
               });
           });*/
       }

   });
   return this;
};
Glide.prototype.ImageFill  = function() {

};




//Slider
$('.posts--carousel').each(function() {
    let $slider = $(this).find('.glide');
    if($(this).hasClass('videos--carousel')) {
        new Glide($slider.get(0), {
            autoplay: 5000,
            animationDuration: 1000,
            type: 'carousel',
            startAt: 0,
            perView: 3,
            breakpoints: {
                575: { //XS
                    perView: 1
                },
                767: { //SM
                    perView: 1
                },
                991: { // MD
                    perView: 2
                },
                1199: { //And down (lg)
                    perView: 3
                }
            }
        }).mount({Controls, Breakpoints,Autoplay});
    } else {
        let postsCarouselGlide = new Glide($slider.get(0), {
            autoplay: 5000,
            animationDuration: 1000,
            type: 'carousel',
            startAt: 0,
            perView: 3,
            gap: 10,
            breakpoints: {
                575: { //XS
                    perView: 1
                },
                767: { //SM
                    perView: 1
                },
                991: { // MD
                    perView: 2
                },
                1399: { //And down (xxl down)
                    perView: 3
                }
            }
        });

        postsCarouselGlide.on(['build.after', 'run.after'], () => {
            if($(window).width() <= 767) {
                let slide = $slider.find('.glide__slides .glide__slide:not(.glide__slide--clone)').get(postsCarouselGlide.index);
                let $slide = $(slide);

                $slider.find('.glide__slides .glide__slide:not(.glide__slide--clone) .slide--card').trigger('mouseleave');

                let $slideCard = $slide.find('.slide--card');
                if(!$slideCard.get(0)) return;

                $slideCard.trigger('mouseenter');
            }
        });

        postsCarouselGlide.LazyLoad();

        postsCarouselGlide.mount({ Controls, Breakpoints, Autoplay });
    }
});


$('.slide--card').each(function() {
    //new VegaFx(this);
    var card = this;
    var self = $(this).find('.grid__item').get(0);

    var stack = $(self).find('.stack').get(0);
    var stackItems = $(stack).children().get();
    var img = $(self).find('.stack__figure > .stack__image').get(0);

    _initEvents();

    function _removeAnimeTargets() {
        anime.remove(stackItems);
        anime.remove(img);
    };

    function _initEvents() {
        var _mouseenterFn = function() {
            _removeAnimeTargets();
            _in();
        };
        var _mouseleaveFn = function() {
            _removeAnimeTargets();
            _out();
        };
        $(card).on('mouseenter', _mouseenterFn);
        $(card).on('mouseleave', _mouseleaveFn);

        //$(self).on('click', _mouseenterFn);
    }

    function _in() {
        stackItems.map(function(e, i) {
            e.style.opacity = i !== self.totalItems - 1 ? 0.2*i+0.2 : 1
        });

        anime({
            targets: stackItems,
            translateZ: [
                {
                    value: function(target, index) {
                        return index*8 + 8;
                    },
                    duration: 200 ,
                    easing: [0.42,0,1,1]
                },
                {
                    value: function(target, index) {
                        return index*20 + 20;
                    },
                    duration: 700,
                    easing: [0.2,1,0.3,1]
                }
            ],
            rotateX: [
                {
                    value: function(target, index) {
                        return -1 * (index*2 + 2);
                    },
                    duration: 200,
                    easing: [0.42,0,1,1]
                },
                {
                    value: 0,
                    duration: 700,
                    easing: [0.2,1,0.3,1]
                }
            ]
        });

        anime({
            targets: img,
            duration: 900,
            easing: [0.2,1,0.3,1],
            scale: 1
        });
    };

    function _out() {
        anime({
            targets: stackItems,
            translateZ: [
                {
                    value: function(target, index) {
                        return index * 20 + 20 - 8;
                    },
                    duration: 200 ,
                    easing: [0.42,0,1,1]
                },
                {
                    value: 0,
                    duration: 900,
                    easing: [0.2,1,0.3,1]
                }
            ],
            rotateX: [
                {
                    value: function(target, index) {
                        return index*2 + 2;
                    },
                    duration: 200,
                    easing: [0.42,0,1,1]
                },
                {
                    value: 0,
                    duration: 900,
                    easing: [0.2,1,0.3,1]
                }
            ],
            opacity: {
                value: function(target, index, cnt) {
                    return index !== cnt - 1 ? 0 : 1
                },
                duration: 900,
                delay: 200,
                easing: [0.2,1,0.3,1]
            }
        });

        anime({
            targets: img,
            duration: 900,
            easing: [0.2,1,0.3,1],
            scale: 1.3
        });
    };
});


$('.image-cover').each(function() {
    $(this).find('img').css({objectFit: 'unset', width: 'auto', height: 'auto'});
    setTimeout(() => {
        $(this).imagefill();
    });
});




















$('.effect-dots').each(function() {
    let $canvas = $('<canvas>');
    $canvas.prependTo($(this));

    let canvas= $canvas.get(0);

    var ctx = canvas.getContext('2d');

    var ww = $(this).width();
    var wh = $(this).height();
    canvas.width = ww;
    canvas.height= wh;
    var partCount = 100;
    var particles = [];

    function particle(){
        this.color = 'rgba(100,100,100,'+ Math.random()+')';
        this.x = randomInt(0,ww);
        this.y = randomInt(0,wh);
        this.direction = {
            "x": -1 + Math.random() * 2,
            "y": -1 + Math.random() * 2
        };
        this.vx = 0.3 * Math.random();
        this.vy = 0.3 * Math.random();
        this.radius = randomInt(2,3);
        this.float = function(){
            this.x += this.vx * this.direction.x;
            this.y += this.vy * this.direction.y;
        };
        this.changeDirection = function (axis) {
            this.direction[axis] *= -1;
        };
        this.boundaryCheck = function () {
            if (this.x >= ww) {
                this.x = ww;
                this.changeDirection("x");
            } else if (this.x <= 0) {
                this.x = 0;
                this.changeDirection("x");
            }
            if (this.y >= wh) {
                this.y = wh;
                this.changeDirection("y");
            } else if (this.y <= 0) {
                this.y = 0;
                this.changeDirection("y");
            }
        };
        this.draw = function () {
            ctx.beginPath();
            ctx.fillStyle = this.color;
            ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
            ctx.fill();
        };
    }
    function clearCanvas() {
        //cloneCtx.clearRect(0, 0, ww, wh);
        ctx.clearRect(0, 0, ww, wh);
    }
    function createParticles(){
        for (var i=0;i<partCount;i++){
            var p = new particle();
            particles.push(p);
        }
    }
    function drawParticles() {
        for (var i=0;i<particles.length;i++) {
            var p = particles[i];
            p.draw();
        }
    }
    function updateParticles() {
        for (var i = particles.length - 1; i >= 0; i--) {
            var p = particles[i];
            p.float();
            p.boundaryCheck();
        }
    }
    createParticles();
    drawParticles();
    function animateParticles() {
        clearCanvas();
        drawParticles();
        updateParticles();
        //cloneCtx.drawImage(canvas, 0, 0);
        requestAnimationFrame(animateParticles);
    }
    requestAnimationFrame(animateParticles);
//cloneCtx.drawImage(canvas, 0, 0);

    $(window).on('resize',function(){
        ww = $(this).width();
        wh = $(this).height();
        canvas.width = ww;
        canvas.height= wh;
        clearCanvas();
        particles = [];
        createParticles();
        drawParticles();
    });
    function randomInt(min,max)
    {
        return Math.floor(Math.random()*(max-min+1)+min);
    }
    function velocityInt(min,max)
    {
        return Math.random()*(max-min+1)+min;
    }
});













//Video slider
$('.video__slider').each(function() {
    let slider = this, $slider = $(this);
    var glide = new Glide(this, {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        gap: 0,
    });

    let activeVideo;


    glide.on(['mount.before', 'run'], function() {
        let slide = $slider.find('.glide__slides .glide__slide:not(.glide__slide--clone)').get(glide.index);
        let $slide = $(slide);


        if(activeVideo) {
            activeVideo.pause();
        }
        if(!$slide.get(0)._videoBuilt) {
            buildVideoSlide($slide);
        } else {
            activeVideo = $slide.find('video').get(0);
            activeVideo.play();
        }
    });


    glide.mount({Controls});

    function buildVideoSlide($slide) {
        let $videoContainer = $slide.find('.video-container');

        let $newVideo = $('<video playsinline muted><source src="'+$videoContainer.data('video')+'" type="video/mp4"></video>');

        let newVideo = $newVideo.get(0);

        newVideo.onloadedmetadata = function() {
            newVideo.play();
            $slide.find('.video-poster').animate({opacity: 0});
            $videoContainer.imagefill({
                target:'video'
            });
        };
        newVideo.onended = function() {
            glide.go('>');
        };

        activeVideo = newVideo;

        $newVideo.appendTo($videoContainer);

        $slide.get(0)._videoBuilt = true;
    }
});






if(is_mobile_device()) {
    let scroll = new Scroll();

    $('.effect-oscar, .effect-flip').each(function() {
        scroll.addAction({
            el: this,
            offset: {
                top: '60%',
                bottom: '-20%'
            },
            in: () => {
                $(this).addClass('hover');
            },
            out: () => {
                $(this).removeClass('hover');
            }
        });
    });
}